import React, { useState } from "react"
import Layout from "../../components/Layout/Layout"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import Page1 from "../../components/Branding/page1"
import Page2 from "../../components/Branding/page2"
const Branding = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const [touch , setTouch] = useState(true)
  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    touchMove:touch,
    dots: true,
  }
  function handleChange(newValue) {
    setTouch(newValue);
  }
  return (
    <Layout>
      <Slider {...settings}>
        <Page1 onChange={handleChange} />
        <Page2 onChange={handleChange} />
      </Slider>
    </Layout>
  )
}
export default Branding
